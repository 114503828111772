<template>
  <div class="attendance-forms-header">
    <div>
      <div class="left-option">
        <Icon :tooltip="opened ? 'Fechar menu lateral' : 'Abrir menu lateral'">
          <BackBurger :class="{ 'back-burger': true, closed: !opened }" @click="handleMenuVisibility" />
        </Icon>
        <div class="title-wrapper" v-if="attendance">
          <div class="form-title">{{ attendanceTitle[attendance.type] }}</div>
          <Edit 
            class="edit-icon"
            v-show="canEditAttendance" 
            @click="editAttendance()" 
            v-b-tooltip.hover title="Editar"
          />          
          <Reprocess 
            class="reprocess-icon" 
            v-if="AllowReprocess"
            v-b-tooltip.hover title="Reprocessar documentos"
            @click="reprocessAttendance"
          />          
        </div>
        <b-skeleton width="85%" v-else></b-skeleton>
      </div>
      <div v-if="AllowReprocess" class="doctor-name">Dr(a) {{ attendance?.professional?.name }}</div>
    </div>
    <div class="right-option">
      <DocumentCounter
        :principal="true"
        :loading="loadingDocs"
        :docs="docs"
        :getAttendanceFiles="getAttendanceFiles"
      />
      <Icon tooltip="Visualizar Exames">
        <div class="icon-wrapper" v-b-modal.exams-modal>
          <Exams class="icon" />
          <b-badge class="badge">{{ exams.length }}</b-badge>
        </div>
      </Icon>
    </div>

    <ExamsModal :exams="exams" />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';

export default {
  props: {
    loadingDocs: Boolean,
    docs: Array,
    exams: Array,
    getAttendanceFiles: Function,
  },
  components: {
    Icon: () => import('@/components/General/Icon'),
    BackBurger: () => import('@/assets/icons/back-burger.svg'),
    Exams: () => import('@/assets/icons/exams.svg'),
    Edit: () => import('@/assets/icons/edit.svg'),
    Reprocess: () => import('@/assets/icons/reprocess.svg'),
    DocumentCounter: () => import('@/components/Attendance/Forms/Components/DocumentCounter'),
    ExamsModal: () => import('@/views/Patients/ExamsModal'),
  },
  data: () => ({
    attendanceTitle: {
      PRE_CONSULTATION: 'Pré-consulta',
      ATTENDANCE: 'Atendimento',
      SURGERY: 'Consulta cirúrgica',
      ANESTHETIST_EVOLUTION: 'Evolução do anestesista',
      TEAM_EVOLUTION: 'Evolução da equipe multidisciplinar',
      TEAM_ANNOTATIONS: 'Anotações da equipe multidisciplinar',
      EXAM: 'Exame',
      BV_PRESCRIPTION: 'Prescrição de óculos BV',
      BV_EXAM: 'Benefício Visão',
      ESSILOR_ATTENDANCE: 'Exames/procedimentos Essilor'
    }
  }),
  computed: {
    ...mapState({
      opened: state => state.attendance.menu.opened,
      attendance: state => state.attendance.attendance,
      canEdit: state => state.attendance.canEdit,
      canEditAttendance() {
        const timeSinceFinish = this.moment(new Date()).diff(this.moment(this.attendance?.end_datetime), 'minutes');
        return (
          !this.canEdit && ( 
            (['PRE_CONSULTATION'].includes(this.attendance?.type) && this.attendance.appointment?.status !== 'FINISHED')
            || (['ATTENDANCE','EXAM'].includes(this.attendance?.type) && timeSinceFinish <= 1440)
          )
        )
      },
      AllowReprocess() {
        return ['FINISHED', 'CLOSED'].includes(this.attendance?.status)
      }
    }),
  },
  methods: {
    ...mapActions('attendance/menu', ['handleMenuVisibility']),
    ...mapActions('attendance', ['editAttendance', 'finishAttendance']),
    reprocessAttendance() {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">Deseja realmente reprocessar o atendimento? Todos os documentos da consulta serão recriados e substituidos.</div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
       }).then(res => {
        if (res.isConfirmed) {
          this.finishAttendance({ noCid: true })
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
  .attendance-forms-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;

    .left-option {
      display: flex;
      align-items: center;
      gap: 10px;
      .back-burger {
        width: 24px;
        height: 24px;
        fill: var(--blue-500);
        cursor: pointer;
        transition: all 600ms;
        &.closed {
          transform: rotate(180deg);
        }
      }
      .title-wrapper {
        display: flex;
        flex-direction: row;
        gap: 0.8rem;
        align-items: center;
        .form-title {
          font-family: Red Hat Display;
          font-weight: 700;
          font-size: 32px;
          color: var(--dark-blue);
          margin-bottom: 0;
        }
        .edit-icon {
          width: 18px;
          height: 18px;
          cursor: pointer;
        }
        .reprocess-icon {
          width: 18px;
          height: 18px;
          cursor: pointer;
          fill: var(--dark-blue);
        }
      }
    }
    .doctor-name {
      font-size: 0.8rem;
    }
    .right-option {
      display: flex;
      align-items: center;
      gap: 20px;
      .icon-wrapper {
        position: relative;
        padding: 8px;
        background: #E7ECFE;
        border-radius: 8px;
        .icon {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
        .badge {
          position: absolute;
          background: var(--blue-700);
          display: flex;
          justify-content: center;
          align-items: center;
          width: 35px;
          height: 20px;
          border-radius: 30px;
          top: -6px;
          right: -15px;
          font-weight: 700;
        }
      }
    }
  }
</style>
